(function() {
	if($('#fullpage-work').length) {
		$(document).ready(function() {
			var afterElement, bp, margin;

			afterElement = window.getComputedStyle(document.body, ':after');
			bp = afterElement.getPropertyValue('content').replace(/\"/g, '');

			margin = bp === 'bp-small' ? 3 : 12;

			Fullpage.init(true, margin);
		});

		$(window).on('load', function() {
			bindDoubleTapEvents(0);
		});
	}

})();