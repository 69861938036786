var _changeBackgroundcolor = function(ele) {
	var bgColor = $(ele).data('background-color');

	$(ele).css('background-color', bgColor);
};

var _resetBackgroundcolor = function(ele) {
	$(ele).css('background-color', '');
};

(function() {
	var itemsSelf = $('.hover-background');
	// var itemsParent = $('.hover-parent-background');

	itemsSelf.each(function() {
		$(this).on('mouseenter', function() {
			_changeBackgroundcolor($(this));
		}).on('mouseleave', function() {
			if(!$(this).parent().hasClass('clicked')) _resetBackgroundcolor($(this));
		});
	});

	// itemsParent.each(function() {
	// 	$(this).on('mouseenter', function() {
	// 		_changeBackgroundcolor($(this).parent().parent());
	// 	}).on('mouseleave', function() {
	// 		if(!$(this).hasClass('clicked')) _resetBackgroundcolor($(this).parent().parent());
	// 	});
	// });
})();