var smoothScrollbar;

(function() {
	$(document).ready(function() {
		var scrollElement = document.getElementById('scroll');

		if ($('#scroll').length) {
			smoothScrollbar = Scrollbar.init(scrollElement, {
				speed: 0.4,
				damping: 0.08
			});

			$('#to-top').click(function() {
				smoothScrollbar.scrollTo(0,20,1200);
			});


			// Due to native #anchor bug, do it manually
			if ($('.c-description__chapter').length) {
				var chapters = $('.c-description__chapter');

				chapters.each(function() {
					$(this).on('click', function() {
						var targetID = $(this).attr('data-id');
						// var target = $(targetID);

						// console.log('test');
						console.log(targetID);
					});
				});
			}
		}
	});
})();