(function() {
	var overlayBtns = $('.c-slideshow-btn').not('.c-slideshow-btn--visible');
	var visibleBtns = $('.c-slideshow-btn--visible');

	if (visibleBtns.length) {
		overlayBtns.on('mouseenter', function() {
			visibleBtns.addClass('blink-opacity');
		})
		.on('mouseleave', function() {
			visibleBtns.removeClass('blink-opacity');
		});
	}
})();