(function() {
	var offset, ele;

	$(".is-smooth").click(function(e){		
		e.preventDefault();

		ele = $($(this).attr('data-id'));

		offset = ele.position().top;

		if (!$(this).hasClass('is-smooth--about')) {
			offset -= 30;
		} else {
			offset += 80;
		}

		if ($('.l-intro').length) {
			_intro.ended();
		}

		smoothScrollbar.scrollTo(0, offset, 1400);
	});
})();