//
//	@parent = (int) amount of layers up to apply clicked
//

var bindDoubleTapEvents = function(parent) {
	var elements = $('.doubletap');

	if (elements.length && getBreakpoint.mediaqueryListener.currentBreakpoint === 'bp-small') {

		elements.on('click', function(e) {
			if (!this.firstClick) {
				e.preventDefault();

				this.firstClick = true;

				_changeBackgroundcolor($(this).children());
				$(this).addClass('clicked');

				if (parent) {
					var p = $(this);

					for (var i = 0; i < parent; i++) {
						p = p.parent();
					}

					p.addClass('clicked');
				}
			}
		});
	}
};