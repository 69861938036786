var _intro;

(function() {
	_intro = {
		s: {
			// Default Values
			windowIsActive: false,
			isDelaying: false,
			reachedNextSlideBorder: false,
			reachedPreviousSlideBorder: false,
			slideBorder: 2,
			isEnding: false,

			// Settings
			noScrollClass: 'h-no-scroll',
			contentClass: '.l-intro__content',
			disableClass: 'is-disabled',
			backgroundColor: '0,0,0',
			delayLength: 200,
			touchDelayLength: 100,

			scrollSpeed: 0.4,
			scrollDampen: 0.08,
			nextSlideSpeed: 2000
		},

		init: function() {
			var s = this.s;

			s.body = $('body');
			s.container = $('.l-intro');
			s.slides = s.container.children().not('.l-intro__menu').toArray();

			this.startSmoothScroll(s.slides);

			s.currentSlide = this.setCurrentSlide(0);
			s.chapterBtn = $('.c-description__chapter a');

			this.disableScrolling();

			this.setWindowMargin();

			// this.bindAnchorEvents();

			this.bindClickEvents();

			this.bindGoToNextSlideEvents();

			this.bindScrollEvents(s.currentSlide.element);

			this.bindWindowResizeEvents();
		},

		// bindAnchorEvents: function() {
		// 	$('.c-description__chapter').each(function() {
		// 		$(this).on('click', function() {
					
		// 		});
		// 	});
		// },

		setWindowMargin: function() {
			var setSize,
					content = $(this.s.currentSlide.content),
					element = $(this.s.currentSlide.element),
					wind = $(window);

			setSize = function() {
				if (element.css('display') !== 'none') {
					$('body').width(content.width());
				}
			};

			if(content.width() !== wind.width()) {
				$(window).on('resize', function() {
					setSize();
				});

				setSize();
			}
		},

		bindClickEvents: function() {
			var self = this;

			$('.l-intro__menu').click(function(e) {
				e.stopPropagation();

				$('#toggle-menu').click();
			});

			if (this.s.slides.length > 1) {
				$(this.s.currentSlide.element).on('click', function(e) {
					var x = e.pageX,
							y = e.pageY;

					self.simulateClick($('.clickthrough'), x, y, false);
				}).on('mousemove', function(e) {
					var x = e.pageX,
							y = e.pageY;

					self.simulateClick($('.clickthrough'), x, y, true);
				});
			}

			// this.s.chapterBtn.click(function() {
			// 	self.enableWindow();
			// });
		},

		bindGoToNextSlideEvents: function() {
			var self = this;

			// If you click somewhere on the body, go to next slide
			$('.l-intro__content').on('click', function() {
				if ($(window).width() > 600) {
					// Go to next slide if the window is big enough
					self.goToNextSlide();
				}
			});

			// Prevent the chapters from firing gotonextslide function
			$('.c-description__chapter > a').on('click', function(e){
				e.stopPropagation();
			})
		},

		startSmoothScroll: function(slides) {
			var self = this,
					scrollbars = [];

			for(var i = 0; i < slides.length; i++) {
				var slide = slides[i],
						scrollbar = Scrollbar.init(slide, {
					speed: self.s.scrollSpeed,
					damping: self.s.scrollDamping
				});

				scrollbars.push(scrollbar);
			}

			this.s.scrollbars = scrollbars;
		},

		bindScrollEvents: function(element) {
			var scrollContent, scrollTop, index, totalSlides, contentBottom,
					self = this,
					s = self.s;

			totalSlides = this.s.slides.length;

			$(element).bind('smoothscroll', function(e) {


				scrollContent = e.content;
				scrollTop = _getPositionInMatrix(scrollContent.css('transform')) * -1;
				contentBottom = Math.floor(s.currentSlide.content.height());

				index = self.s.currentSlide.index;

				if(!self.s.isEnding) {
					// If ended isn't called, e.g. when smoothscroll isn't triggered by scrolling
					// Transition background acording to progress
					self.transitionBackground(self.getSlideProgress(scrollTop));
				}

				// To prevent the prev slide from instantly start
				// Set reachedStartBorder to true if the scrolling has gone past 100
				// Check state listens to this variable to determine prev slide
				if (scrollTop > self.s.slideBorder && !s.reachedNextSlideBorder) {
					s.reachedNextSlideBorder = true;
				}






				if (scrollTop < contentBottom - self.s.slideBorder && !s.reachedPreviousSlideBorder) {
					s.reachedPreviousSlideBorder = true;

				}

				switch(self.checkState(scrollTop)) {
					case 'reached top':
						if(index - 1 >= 0) self.previousSlide();

						break;
					case 'reached bottom':
						if (index + 1 < totalSlides) {
							self.nextSlide();
						} else {
							self.enableWindow();
						}

						break;
				}
			});
		},

		bindWindowScrollEvents: function() {
			var self = this, scrollContent, scrollTop;

			$('#scroll').bind('smoothscroll', function(e) {
				scrollContent = e.content;
				scrollTop = _getPositionInMatrix(scrollContent.css('transform')) * -1;

				if(scrollTop > self.s.slideBorder && !self.s.reachedNextSlideBorder) {
					self.s.reachedNextSlideBorder = true;
				}

				if (scrollTop <= 2 && self.s.reachedNextSlideBorder) {

					self.disableWindow();
				}
			});
		},

		bindWindowResizeEvents: function() {
			var self = this;
			var container = this.s.container;

			$(window).on('resize', function() {
				if (container.hasClass("is-disabled")) {

					for (var i = 0; i < self.s.scrollbars.length; i++) {
						var bar = self.s.scrollbars[i];

						// bar.scrollTo(0, $(window).height(), 0);
					}

					// container.find('.l-intro__container').each(function() {
					// 	var inner = $(this);
					// 	var scrollcontent = inner.find('scroll-content');
					// 	var negativeHeight = $(this).height() * -1;




					// 	scrollcontent.css('transform', 'translate3d(0px,' + negativeHeight + 'px, 0)');
					// });

				}
			});
		},

		simulateClick: function(el, x, y, hover) {
			var type = hover ? 'onmouseover' : 'click',
				ev = new MouseEvent(type, {
			        'view': window,
			        'bubbles': false,
			        'cancelable': true,
			        'screenX': x,
			        'screenY': y
		    });

	    var cur = $(this.s.currentSlide.element);
	    var scrollContent = cur.find('.scroll-content');
	    var introBottom = cur.height() + _getPositionInMatrix(scrollContent.css('transform'));

	    el.each(function() {
	    	var off = $(this).offset(),
	    			w = $(this).width(),
	    			h = $(this).height();

	    	off.top = off.top;

	    	if (x > off.left && x < (off.left + w)) {
	    		// Within x range

	    		if (y > off.top && y < (off.top + h) && introBottom < y) {
	    			// Within x and y range

	    			if (hover) {
	    				$(this).addClass('is-hovering');
	    			} else { 
	    				$(this).click();
	    				$(this).removeClass('is-hovering');
	    			}

	    		} else if ($(this).hasClass('is-hovering')) {
		    		$(this).removeClass('is-hovering');
		    	}

	    	} else if ($(this).hasClass('is-hovering')) {
	    		$(this).removeClass('is-hovering');
	    	}
	    });
		},

		getSlideProgress: function(scrollTop) {
			var bottom, scrollTop, current = this.s.currentSlide;

			bottom = $(current.content).height();

			return (scrollTop / bottom).toFixed(2);
		},

		transitionBackground: function(progress) {
			var current = this.s.currentSlide;

			if (progress) {

			} else {


			}

			if (typeof progress === 'undefined') {
				$(current.element).css('background-color', 'rgba(0,0,0,0)');
			} else {
				$(current.element).css('background-color', 'rgba(' + this.s.backgroundColor + ',' + (1 - progress) + ')');
			}
		},

		unbindScrollEvents: function(element) {
			$(element).unbind('smoothscroll');
		},

		setCurrentSlide: function(index) {
			var slide, self = this;

			slide = {
				index: index,
				element: self.s.slides[index],
				content: $(self.s.slides[index]).find(self.s.contentClass),
				scrollbar: self.s.scrollbars[index]
			};

			return slide;
		},

		nextSlide: function(scrollTop) {
			var self = this;



			this.disableSlide(this.s.currentSlide);

			this.unbindScrollEvents(this.s.currentSlide.element);

			this.s.currentSlide = this.setCurrentSlide(this.s.currentSlide.index + 1);
			this.s.reachedNextSlideBorder = false;

			// this.startSmoothScroll(this.s.currentSlide);
			this.bindScrollEvents(this.s.currentSlide.element);
		},

		

		previousSlide: function() {







			this.unbindScrollEvents(this.s.currentSlide.element);

			this.s.currentSlide = this.setCurrentSlide(this.s.currentSlide.index - 1);
			this.s.reachedPreviousSlideBorder = false;

			this.enableSlide(this.s.currentSlide);

			this.bindScrollEvents(this.s.currentSlide.element);
		},

		enableWindow: function(scrollTop) {
			$('body').removeClass(this.s.noScrollClass);
			$('body').css('width', 'auto');
			


			this.s.reachedNextSlideBorder = false;
			this.unbindScrollEvents($(this.s.currentSlide.element));

			this.s.container.addClass(this.s.disableClass);
			this.disableSlide(this.s.currentSlide);

			_stickyMenuInit();

			this.bindWindowScrollEvents();
		},

		disableWindow: function() {

			$('body').addClass(this.s.noScrollClass);
			this.s.container.removeClass(this.s.disableClass);

			this.unbindScrollEvents($('#scroll'));

			this.enableSlide(this.s.currentSlide);

			this.s.reachedPreviousSlideBorder = false;

			this.bindScrollEvents(this.s.currentSlide.element);
		},

		repositionScrollTop: function(scrollTop) {
			this.s.currentSlide.find('.scroll-content').css('transform', 'translate3d(0,' + scrollTop * -1 + 'px,0)');
		},

		disableSlide: function(slide) {
			$(slide.element).addClass(this.s.disableClass);
		},

		enableSlide: function(slide) {
			$(slide.element).removeClass(this.s.disableClass);
		},

		goToNextSlide: function() {
			var currentScrollbar = this.s.scrollbars[this.s.currentSlide.index];
			
			currentScrollbar.scrollTo(0, currentScrollbar.limit.y + 5, this.s.nextSlideSpeed);




			if (this.s.currentSlide.index >= this.s.scrollbars.length - 1) {
				// self.s.reachedNextSlideBorder;
				smoothScrollbar.scrollTo(0,10,100);

			}
		},

		ended: function() {
			var self = this,
					scrollbar = this.s.currentSlide.scrollbar,
					offset = $(this.s.currentSlide.content).outerHeight();

			self.s.isEnding = true;
			this.transitionBackground();

			scrollbar.scrollTo(0, offset, 600, function() {
				self.s.isEnding = false;
			});

			if(this.s.currentSlide.index < this.s.slides.length - 1) {
				// First slide still open
				this.nextSlide();
				this.transitionBackground();

				scrollbar = this.s.currentSlide.scrollbar;
				offset = $(this.s.currentSlide.content).outerHeight();

				scrollbar.scrollTo(0, offset, 600);
			}
		},


		exit: function() {
			this.s.container.css('transform', 'translate3d(0,' + $(window).height() * -1 + 'px,0)');
		},

		

		checkState: function(scrollTop) {
			var state, current, contentBottom;

			current = this.s.currentSlide;
			contentBottom = Math.floor(current.content.innerHeight());





			// Content bottom - 2 because it sometimes get stuck at 1 below the content
			if (contentBottom - 2 <= scrollTop && this.s.reachedPreviousSlideBorder) {
				state = 'reached bottom';
			} else if (scrollTop < 3 && scrollTop >= 0 && current.index !== 0 && this.s.reachedNextSlideBorder) {
				state = 'reached top';
			} else {
				state = false;
			}



			return state;
		},

		disableScrolling: function() {
			var noScrollClass = this.s.noScrollClass;

			this.s.body.addClass(noScrollClass)
		},

		enableScrolling: function() {
			var noScrollClass = this.s.noScrollClass;

			this.s.body.removeClass(noScrollClass)
		}
	};
})();
