(function() {
	var slideshow = $('.is-slideshow'),
			description = $('.c-description'),
			intro = $('.l-intro'),
			lastDescriptionHeight = 0;

	if (description.length) {
		function positionIntroContent() {
			var dif;

			if (description.outerHeight() < intro.height() && description.outerHeight() !== 0) {
				dif = intro.height() - description.outerHeight();

				description.parent().css('padding-top', dif / 2 + 'px');

				lastDescriptionHeight = description.outerHeight();
			} else if (description.outerHeight() === 0 && $(window).height() > lastDescriptionHeight) {

				dif = $(window).height() - lastDescriptionHeight;

				description.parent().css('padding-top', dif / 2 + 'px');
			}
		}

		$(document).ready(function() {
			positionIntroContent();

			// if ($(document).scrollTop() !== 0) {
			// 	$(document).scrollTop(0);
			// }
		});

		$(window).resize(function() {
			positionIntroContent();
		});
	}

	$(document).ready(function() {

		if(intro.length) {
			// Initialise intro
			_intro.init();
		}

		if(slideshow.length) {
			// Initialise slideshow
			slideshow.each(function() {
				var nextBtn, prevBtn, parent;

				parent = $(this).parent();

				nextBtn = parent.find('.c-slideshow-btn--next');
				prevBtn = parent.find('.c-slideshow-btn--prev');

				$(this).slick({
					arrows: true,
					infinite: false,
					nextArrow: nextBtn,
					prevArrow: prevBtn,
					slidesToShow: 1,
			        slidesToScroll: 1,
			        dots: true,
			        swipe: true,
			        dotsClass: 'l-pagination'
				});
			});
		}
	});
})();