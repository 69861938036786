var _stickyMenuInit,
		_didScroll = false;

(function() {
	var body = $('body'),
			openClass = 'menu-is-open',
			toggleBtn = $('#toggle-menu'),
			fullpageArrows = $('.c-fullpage-arrow'),
			lastScrollTop = 0,
			delta = 5,
			header = $('.l-header'),
			navbarHeight = $('.l-header').height();

	toggleBtn.on('click', function(e) {
		e.preventDefault();

		if(!body.hasClass(openClass)) {
			body.addClass(openClass);

			if (fullpageArrows.length) fullpageArrows.addClass('is-hidden');
		} else {
			body.removeClass(openClass);

			if (fullpageArrows.length) fullpageArrows.removeClass('is-hidden');
		}
	});

	if($('.menu-is-sticky').length) {
		_stickyMenuInit = function() {
			$('#scroll').on('smoothscroll', function(){
		    _didScroll = true;
			});
		}

		if (!$('.l-case').length) {
			_stickyMenuInit();
		}

		setInterval(function() {
		    if (_didScroll) {
		        hasScrolled();
		        _didScroll = false;
		    }
		}, 250);

		function hasScrolled() {
		    var st = $('#scroll').children('.scroll-content').css('transform');
		    st = _getPositionInMatrix(st) * -1;
		    
		    // Make sure they scroll more than delta
		    if(Math.abs(lastScrollTop - st) <= delta)
		        return;
		    
		    // If they scrolled down and are past the navbar, add class .nav-up.
		    // This is necessary so you never see what is "behind" the navbar.
		    if (st > lastScrollTop && st > navbarHeight && !$('body').hasClass('menu-is-open')){
		        // Scroll Down
		        header.addClass('l-header--is-hidden');
		    } else {
		        // Scroll Up
		        header.removeClass('l-header--is-hidden');
		    }
		    
		    lastScrollTop = st;
		}
	}
})();