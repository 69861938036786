	
var Fullpage;

(function() {
	Fullpage = {
		s: {
			// Default variables
			isSliding: false,
			delta: 0,
			footerIsOpen: false,
			timeout: false,
			scrollMove: {
				timer: false,
				i: 0,
				hasSlided: false,
			},


			// Settings
			min: 40,
			slideLength: 600,
			scrollTimeoutLength: 600,
			scrollPauseLength: 50,
			colTransitionClass: 'c-fullpage-col--transition',
			touchSwipeMin: 200
		},

		init: function(solo, marginBottom) {
			var s = this.s;

			s.solo 						= solo;
			s.wrapper 				= $('.l-fullpage');
			s.wrapperHeight 	= s.wrapper.height();
			s.leftCol 				= $('.c-fullpage-col--left');
			s.leftSlides 			= s.leftCol.children().toArray();

			if (!solo) {
				s.rightCol 				= $('.c-fullpage-col--right');
				s.rightSlides 		= s.rightCol.children().toArray().reverse();
			}

			s.prevBtn 				= $('#prev');
			s.nextBtn 				= $('#next');
			s.introBtn				= $('.c-module--intro');
			s.footer 					= $('.c-footer').parent();
			s.currentSlide 		= 0;
			s.totalSlides 		= s.leftCol.children().toArray().length;
			s.marginBottom		= typeof marginBottom === 'undefined' ? false : marginBottom;
			s.orientation 		= 'is-landscape';

			if (s.marginBottom) s.wrapperHeight += s.marginBottom;

			$('body').addClass('h-no-scroll');

			this.checkOrientation();
			this.fixVideoEmptySpace();
			this.positionCols(0, false);
			this.revealSlideshow();

			this.bindScrollEvents();
			this.bindClickEvents();
			this.bindContinuousClick();
			this.bindTouchEvents();
			this.bindKeyboardEvents();

			if (!solo) {
				this.bindHoverEvents();
			}

			this.bindWindowResizeEvents();
		},

		fixVideoEmptySpace: function() {
			var dec, width;

			$('.c-module--video').each(function() {
				width = $(this).width();
				dec = width - Math.floor(width);

				if (dec < 0.5 && dec > 0) {
					$(this).width(Math.ceil(width));
				} else {
					$(this).css('width', '');
				}
			});
		},

		setSlideTimer: function() {
			var self = this;

			self.s.timeout = null;

			self.s.timeout = setTimeout(function() {
				self.resetSlideSettings();
			}, self.s.scrollTimeoutLength);
		},

		clearSlideTimer: function() {
			var self = this;

			if(self.s.timeout)  {
				clearTimeout(self.s.timeout);
			}
		},

		clearScrollMoveTimer: function() {
			var scrollMove = this.s.scrollMove;

			if (scrollMove.timer) {
				clearTimeout(scrollMove.timer);
			}
		},

		bindScrollEvents: function() {
			var delta = 0, deltaNorm, self = this;

			$(window).on('mousewheel', function(e) {
				self.clearSlideTimer();
				self.clearScrollMoveTimer();

				self.s.delta += e.deltaY * e.deltaFactor;
				deltaNorm = self.s.delta < 0 ? self.s.delta * -1 : self.s.delta;

				// currentScrollMove()

				// Check if it's in a scroll move
				// currentScrollMove = ;

				// check if it has slided during scroll move
				// slidedWhileScrolling = ;


				if (deltaNorm > self.s.min && !self.s.isSliding && !self.s.scrollMove.hasSlided) {
					if (self.s.delta > 0 && self.s.footerIsOpen) {
						self.closeFooter();
						self.s.isSliding = true;
						self.s.footerIsOpen = false;
						self.checkBtnVisibility();
						self.s.scrollMove.hasSlided = true;
					} else if (self.s.delta < 0 && self.isInLimit(1)) {
						self.slideNext();
						self.s.isSliding = true;
						self.checkBtnVisibility();
						self.s.scrollMove.hasSlided = true;
					} else if (self.s.delta > 0 && self.isInLimit(-1)) {
						self.slidePrev();
						self.s.isSliding = true;
						self.checkBtnVisibility();
						self.s.scrollMove.hasSlided = true;
					} else if (self.s.delta < 0 && !self.isInLimit(1) && !self.s.footerIsOpen) {
						self.openFooter();
						self.s.isSliding = true;
						self.s.footerIsOpen = true;
						self.checkBtnVisibility();
						self.s.scrollMove.hasSlided = true;
					}
				}

				self.setSlideTimer();
				self.setScrollMoveTimer();
			});
		},

		setScrollMoveTimer: function() {
			var scrollMove = this.s.scrollMove;
			var self = this;


			scrollMove.timer = setTimeout(function() {

				scrollMove.i++;
				scrollMove.hasSlided = false;
			}, self.s.scrollPauseLength);
		},

		bindClickEvents: function() {
			var s = this.s,
					self = this;

			s.prevBtn.on('click', function() {
				self.clearSlideTimer();

				if(!self.s.isSliding) {
					if(self.isInLimit(-1)) {
						if (!self.s.footerIsOpen) {
							self.slidePrev();

							self.s.isSliding = true;
							self.checkBtnVisibility();
						} else {
							self.closeFooter();

							self.s.footerIsOpen = false;
							self.checkBtnVisibility();
						}
					}
				}

				self.setSlideTimer();
			});

			s.nextBtn.on('click', function() {
				self.clearSlideTimer();

				if (!self.s.isSliding) {
					if (self.isInLimit(1)) {
						self.slideNext();

						self.s.isSliding = true;
						self.checkBtnVisibility();
					} else if (!self.s.footerIsOpen) {
						self.openFooter();

						self.s.isSliding = true;

						self.s.footerIsOpen = true;
						self.checkBtnVisibility();
					}
				}

				self.setSlideTimer();
			});

			s.introBtn.on('click', function(e) {
				e.stopPropagation();

				self.slideNext();
			});
		},

		bindContinuousClick: function() {
			var self = this;

			// When clicked on wrapper element, go to next slide 
			$('#fullpage-home').bind('click', function() {
				if (!self.s.isSliding) {
					if (self.isInLimit(1)) {
						self.slideNext();

						self.s.isSliding = true;
						self.checkBtnVisibility();
					} else if (!self.s.footerIsOpen) {
						self.openFooter();

						self.s.isSliding = true;

						self.s.footerIsOpen = true;
						self.checkBtnVisibility();
					}
				}
			});

			// Prevent propagation for all links on top of the wrapper element
			$('.c-module__active-block').on('click', function(e) {
				e.stopPropagation();
			});
		},

		unbindContinuousClick: function() {
			$('#fullpage-home').unbind('click');
		},

		bindKeyboardEvents: function() {
			var s = this.s,
					self = this;

			$(document).keydown(function(e) {
				self.clearSlideTimer();

				if(!self.s.isSliding) {
					switch(e.which) {
		        case 38: // up
							if(self.isInLimit(-1)) {
								if (!self.s.footerIsOpen) {
									self.slidePrev();

									self.s.isSliding = true;
									self.checkBtnVisibility();
								} else {
									self.closeFooter();

									self.s.footerIsOpen = false;
									self.checkBtnVisibility();
								}
							}

		        break;

		        case 40: // down
								if (self.isInLimit(1)) {
									self.slideNext();

									self.s.isSliding = true;
									self.checkBtnVisibility();
								} else if (!self.s.footerIsOpen) {
									self.openFooter();

									self.s.isSliding = true;

									self.s.footerIsOpen = true;
									self.checkBtnVisibility();
								}
		        break;

		        default: return; // exit this handler for other keys
			    }
				}

				self.setSlideTimer();

		    e.preventDefault(); // prevent the default action (scroll / move caret)
			});
		},

		bindHoverEvents: function() {
			var i, left, right, hoverText, activeElement,
					self = this,
					hoverClass = 'c-module--is-hovering',
					childClass = '.c-module',
					leftSlides = self.s.leftSlides,
					rightSlides = self.s.rightSlides;

			for (i = 0; i < leftSlides.length; i++) {
				left = $(leftSlides[i]);
				activeElement = left.find('.c-module__active-block');
				hoverText = left.find('.c-module__hover');

				if (left.hasClass('c-fullpage-col__slide--large')) {
					right = $(rightSlides[i]);

					this.bindBackroundHoverEvents(activeElement, hoverText, right.find(childClass));
					// this.bindBackroundHoverEvents(right, left.find(childClass));
				} else {
					this.bindBackroundHoverEvents(activeElement, hoverText, false);
				}
			}

			for (i = 0; i < rightSlides.length; i++) {
				right = $(rightSlides[i]);
				activeElement =  right.find('.c-module__active-block');

				if (right.hasClass('c-fullpage-col__slide--large')) {
					left = $(leftSlides[i]);
					hoverText = left.find('.c-module__hover');

					this.bindBackroundHoverEvents(activeElement, hoverText, left.find(childClass));
				} else {
					hoverText = right.find('.c-module__hover');

					this.bindBackroundHoverEvents(activeElement, hoverText, false);
				}
			}
		},

		bindBackroundHoverEvents: function(item, hoverText, sibling) {
			var hoverClass = 'c-module--is-hovering';
			var self;

			item.on('mouseenter click', {sibling: sibling, hoverText: hoverText}, function(e) {
				self = $(this);

				e.data.hoverText.removeClass('is-hidden');
				_changeBackgroundcolor($(this).parent().parent());

				console.log(e.data.sibling);

				if (sibling !== false) {
					_changeBackgroundcolor(e.data.sibling);
					e.data.sibling.addClass(hoverClass);
				}

			}).on('mouseleave', {sibling: sibling, hoverText: hoverText}, function(e) {
				self = $(this);

				if (!self.hasClass('clicked')) {
					e.data.hoverText.addClass('is-hidden');
					_resetBackgroundcolor($(this).parent().parent());

					if (sibling !== false) {
						_resetBackgroundcolor(e.data.sibling);
						e.data.sibling.removeClass(hoverClass);
					}
				}
			});;
		},

		bindPortraitHoverEvents: function() {
			var i, left, right, hoverText, activeElement,
					self = this,
					childClass = '.c-module',
					leftSlides = this.s.leftSlides,
					rightSlides = $('.c-fullpage-col--left').find('.c-fullpage-col__slide--right');

			for (i = 0; i < leftSlides.length; i++) {
				left = $(leftSlides[i]);

				activeElement = left.find('.c-module__active-block');
				hoverText = left.find('.c-module__hover');

				if (left.hasClass('c-fullpage-col__slide--large')) {
					right = left.prev().children().first();

					this.bindBackroundHoverEvents(activeElement, hoverText, right);
				} else {
					this.bindBackroundHoverEvents(activeElement, hoverText, false);
				}
			}

			rightSlides.each(function() {
				right = $(this);

				console.log(right);

				activeElement = right.find('.c-module__active-block');
				hoverText = right.find('.c-module__hover');

				if (hoverText.length) {
					self.bindBackroundHoverEvents(activeElement, hoverText, false);
				}
			});
		},

		bindTouchEvents: function() {
			var timeout,
					self = this,
					wrapper = $('.l-fullpage__inner'),
					hammer = new Hammer(wrapper[0]);

			hammer.get('swipe').set({direction: Hammer.DIRECTION_VERTICAL});

			hammer.on('swipeup swipedown', function(e) {
				if (timeout) {
					clearTimeout(timeout);
				}

				e.preventDefault();

				if(!self.s.isSliding) {

					if(e.type === 'swipeup') {

						if (self.isInLimit(1)) {
							self.slideNext();
							self.s.isSliding = true;
							self.checkBtnVisibility();
						} else if (!self.isInLimit(1) && !self.s.footerIsOpen) {
							self.openFooter();
							self.s.isSliding = true;
							self.s.footerIsOpen = true;
							self.checkBtnVisibility();
						}

					} else {

						if (self.s.footerIsOpen) {
							self.closeFooter();
							self.s.isSliding = true;
							self.s.footerIsOpen = false;
							self.checkBtnVisibility();
						} else if (self.isInLimit(-1)) {
							self.slidePrev();
							self.s.isSliding = true;
							self.checkBtnVisibility();
						}

					}

				}

				timeout = setTimeout(function() {
					self.resetSlideSettings();
				}, self.s.scrollTimeoutLength);
			});
		},

		bindWindowResizeEvents: function() {
			var self = this;

			$(window).on('resize', function() {
				self.fixVideoEmptySpace();

				self.s.wrapperHeight = self.s.wrapper.height();
				if (self.s.marginBottom) self.s.wrapperHeight += self.s.marginBottom;

				self.positionCols(self.s.currentSlide, false);

				if(self.s.footerIsOpen) self.openFooter();

				self.checkOrientation();
			});
		},

		checkOrientation: function() {
			if ($('body').hasClass('is-portrait') && this.s.orientation !== 'is-portrait') {
				// Switching to portrait
				if(!this.s.solo) {
					this.reverseOrder();
					this.combineLists();

					this.bindPortraitHoverEvents();

					this.s.introBtn = $('.c-module--intro');

					this.unbindContinuousClick();
					bindDoubleTapEvents();
				}

				this.s.orientation = this.getOrientationClass();
			} else if ($('body').hasClass('is-landscape') && this.s.orientation !== 'is-landscape') {
				// Switching to landscape
				if(!this.s.solo) {
					this.reverseOrder();
					this.decombineLists();

					this.bindHoverEvents();

					this.s.introBtn = $('.c-module--intro');

					this.bindContinuousClick();
					bindDoubleTapEvents();
				}

				this.s.orientation = this.getOrientationClass();
			}
		},

		getOrientationClass: function() {
			return $('body').hasClass('is-landscape') ? 'is-landscape' : 'is-portrait';
		},

		reverseOrder: function() {
			var list = $(this.s.rightCol),
					listItems = list.children();

			list.append(listItems.get().reverse());
		},

		combineLists: function() {
			var slide, leftSlides, rightSlides;

			leftSlides = this.s.leftSlides;
			rightSlides = this.s.rightSlides;


			for (var i = 0; i < rightSlides.length; i++) {
				slide = $(rightSlides[i]).clone(true);


				if (!slide.hasClass('c-fullpage-col__slide--large')) {
					$(leftSlides[i]).before(slide);
				} else {
					$(leftSlides[i]).wrap('<div class="c-fullpage-col__slide-wrapper"></div>');
					$(leftSlides[i]).before(slide);
				}
			}
		},

		decombineLists: function() {
			for(var i = 0; i < this.s.leftSlides.length; i++) {
				var leftslide = $(this.s.leftSlides[i]);

				if(leftslide.hasClass('c-fullpage-col__slide--large')) {
					leftslide.unwrap();
				}
			}

			$(this.s.leftCol).find('.c-fullpage-col__slide--right').remove();
		},

		positionCols: function(slide, transition) {
			if (transition) {
				var transitionClass = this.s.colTransitionClass,
						leftCol = this.s.leftCol;

				if(!this.s.solo) var rightCol = this.s.rightCol;

				this.addTransitionClass(leftCol, transitionClass);
				if(!this.s.solo) this.addTransitionClass(rightCol, transitionClass);
			}

			this.leftColPos(slide);
			if(!this.s.solo) this.rightColPos(slide);

			if (transition) {
				this.removeTransitionClass(leftCol, transitionClass);
				if(!this.s.solo) this.removeTransitionClass(rightCol, transitionClass);
			}
		},

		revealSlideshow: function() {
			$('body').addClass('fullpage-is-loaded');
		},

		rightColPos: function(slide) {
			var lastPos, pos, offset,
					rightCol = this.s.rightCol,
					totalSlides = rightCol.children().toArray().length;

			if ($('body').hasClass('is-landscape')) {
				startPos = (totalSlides - 1) * this.s.wrapperHeight * -1;
				offset = this.s.wrapperHeight * slide;
				pos = startPos + offset;
			} else {
				pos = this.s.wrapperHeight * (slide) * -1;
			}

			rightCol.css('transform', 'translate3d(0,' + pos + 'px, 0)');
		},

		leftColPos: function(slide) {
			var slideHeight, pos, leftCol = this.s.leftCol,

			pos = this.s.wrapperHeight * (slide) * -1;

			leftCol.css('transform', 'translate3d(0,' + pos + 'px, 0)');
		},

		isInLimit: function(dir) {
			var children, target, positionBottomTarget, lastSlideBottom, inLimit;

			children = this.s.leftCol.toArray();
			target = this.s.currentSlide + dir;

			if (dir < 0) {
				inLimit = target < 0 ? false : true;
			} else {
				var lastChildren = $(children[children.length - 1]).children().toArray(),
						lastChild = lastChildren[lastChildren.length - 1],
						wrapperOffset = this.s.wrapper.offset().top;

				lastSlideBottom = $(lastChild).offset().top + $(lastChild).height() - (this.s.wrapperHeight * dir) - wrapperOffset;

				inLimit = lastSlideBottom <= 0 ? false : true;
			}

			return inLimit;
		},

		addTransitionClass: function(ele, transitionClass) {
			ele.addClass(transitionClass);
		},

		removeTransitionClass: function(ele, transitionClass) {
			var self = this;

			setTimeout(function() {
				ele.removeClass(transitionClass);
			}, self.s.slideLength);
		},

		slideNext: function() {
			this.s.currentSlide += 1;

			this.positionCols(this.s.currentSlide, true);

			this.checkBtnVisibility();

			this.slideTimer();

			lazySizes.loader.checkElems();
		},

		slidePrev: function() {
			this.s.currentSlide -= 1;

			this.positionCols(this.s.currentSlide, true);

			this.checkBtnVisibility();

			this.slideTimer();

			lazySizes.loader.checkElems();
		},

		openFooter: function(stayOpen) {
			var offset = this.s.wrapperHeight * -1;

			if (this.s.marginBottom) offset += this.s.marginBottom;

			if(!this.s.footerIsOpen) {
				this.s.currentSlide += 1;

				this.positionCols(this.s.currentSlide, true);

				this.checkBtnVisibility();

				this.addTransitionClass(this.s.footer, this.s.colTransitionClass);
			}

			this.s.footer.css('transform', 'translate3d(0,' + offset + 'px,0)');

			if(!this.s.footerIsOpen) this.removeTransitionClass(this.s.footer, this.s.colTransitionClass);
		},

		closeFooter: function() {
			this.s.currentSlide -= 1;

			this.positionCols(this.s.currentSlide, true);

			this.addTransitionClass(this.s.footer, this.s.colTransitionClass);

			this.s.footer.css('transform', 'translate3d(0,0,0)');

			this.removeTransitionClass(this.s.footer, this.s.colTransitionClass);
		},

		slideTimer: function() {
			var self = this;

			setTimeout(function() {
				// Done Sliding
				self.resetSlideSettings();

				// Call lazysizes loader to check if any elements are in viewport
				lazySizes.loader.checkElems();

			}, self.s.slideLength);
		},

		resetSlideSettings: function() {
			this.s.isSliding = false;
			this.s.delta = 0;
		},

		checkBtnVisibility: function() {
			var s = this.s;

			if (s.currentSlide - 1 < 0) {
				s.prevBtn.addClass('is-hidden');
			} else {
				s.prevBtn.removeClass('is-hidden');
			}

			if (s.footerIsOpen) {
				s.nextBtn.addClass('is-hidden');
			} else {
				s.nextBtn.removeClass('is-hidden');
			}
		},


	};
})();