(function() {
	if($('#fullpage-home').length) {
		$(document).ready(function() {
			Fullpage.init(false, 0);

			// $('.c-module__active-block').on('mouseenter', function() {
			// 	$(this).next().removeClass('is-hidden');
			// }).on('mouseleave', function() {
			// 	$(this).next().addClass('is-hidden');
			// });
		});

		$(window).on('load', function() {
			bindDoubleTapEvents(3);
		});
	}
})();