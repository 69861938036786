var _getPositionInMatrix;

(function() {

	_getPositionInMatrix = function(matrix) {
		var matrixPattern = /^\w*\((((\d+)|(\d*\.\d+)),\s*)*((\d+)|(\d*\.\d+))\)/i;
		var matrixValue = [];

		var matrixCopy = matrix.replace(/^\w*\(/, '').replace(')', '');
		matrixValue = matrixCopy.split(/\s*,\s*/);

		matrixValue = matrixValue[matrixValue.length - 1];

    return Number(matrixValue);
	};

	$(document).ready(function() {
		var isPortrait, body;

		body = $('body');

		// Check for touch events
		if ('ontouchstart' in window || navigator.maxTouchPoints) {
			body.addClass('is-touch');
		}

		// Check if browser is portrait
		isPortrait = function() {
			return window.innerHeight >= window.innerWidth;
		};

		setOrientationClass = function() {
			if (isPortrait() && !body.hasClass('is-portrait')) {
				body.removeClass('is-landscape');
				body.addClass('is-portrait');
			} if (!isPortrait() && !body.hasClass('is-landscape')) {
				body.removeClass('is-portrait');
				body.addClass('is-landscape');
			}
		};

		setOrientationClass();

		$(window).on('resize', function() {
			setOrientationClass();
		});	
	});
})();